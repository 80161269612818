<template>
  <div v-if="info">
    <TogglePanel :title="title" data-testid="toggle-panel">
      <TogglePanel
        v-if="headerFields"
        :title="$tc('rich_text.heading', 1)"
        :icon="{
          collapse: 'fa-caret-square-o-up',
          expand: 'fa-caret-square-o-down'
        }"
        :collapsed="!panels.header"
        :persistent="false"
        @changed="panels.header = !$event"
      >
        <div style="padding: 0 0 0 5px">
          <TogglePanel
            :title="`${$t('title')}`"
            :icon="{
              collapse: 'fa-caret-square-o-up',
              expand: 'fa-caret-square-o-down'
            }"
            :collapsed="!panels.title"
            :persistent="false"
            @changed="panels.title = !$event"
          >
            <div class="form-group form-group-sm">
              <label class="label-inline" for="title">{{ $t("text") }}</label>
              <div class="input-group">
                <input
                  :placeholder="`${$t('text')}`"
                  class="form-control"
                  v-model="titleText"
                  ref="inpTitle"
                />
                <div
                  class="input-group-addon btn"
                  @click.stop.prevent="titleText = ''"
                >
                  <i class="fa fa-close"></i>
                </div>
              </div>
            </div>
            <ControlStyleProperties v-model="titleStyle" />
          </TogglePanel>
          <TogglePanel
            :title="`${$t('subtitle')}`"
            :icon="{
              collapse: 'fa-caret-square-o-up',
              expand: 'fa-caret-square-o-down'
            }"
            :collapsed="!panels.subTitle"
            :persistent="false"
            @changed="panels.subTitle = !$event"
            style="margin-top: -5px"
          >
            <div class="form-group form-group-sm">
              <label class="label-inline" for="subTitle">{{
                $t("subtitle")
              }}</label>
              <div class="input-group">
                <input
                  :placeholder="`${$t('text')}`"
                  class="form-control"
                  v-model="subTitleText"
                  ref="inpSubTitle"
                />
                <div
                  class="input-group-addon btn"
                  @click.stop.prevent="subTitleText = ''"
                >
                  <i class="fa fa-close"></i>
                </div>
              </div>
            </div>
            <ControlStyleProperties v-model="subTitleStyle" />
          </TogglePanel>
        </div>
      </TogglePanel>
      <!-- important while in use by the synoptic -->
      <div v-else style="margin-top: 10px"></div>
      <TogglePanel
        class="contentPanel"
        :title="$t('table')"
        :icon="{
          collapse: 'fa-caret-square-o-up',
          expand: 'fa-caret-square-o-down'
        }"
      >
        <div style="padding: 0px 10px 20px 10px">
          <ControlStyleProperties
            v-model="tableCellStyle"
            :togglePanelIcons="{
              collapse: 'fa-caret-square-o-up',
              expand: 'fa-caret-square-o-down'
            }"
          >
            <template #after>
              <div style="margin: 20px 0 10px 0">
                <label for="">{{ $tc("style") }}</label>
                <div class="inner-panel">
                  <div
                    v-for="(item, ix) in tableClasses"
                    :key="ix"
                    class="clicable form-group"
                    @click.stop.prevent="toggleTableClass(ix)"
                  >
                    <i class="fa fa-check-square-o" v-if="item.value"></i>
                    <i class="fa fa-square-o" v-else></i>
                    <span> {{ item.title }}</span>
                  </div>
                </div>
              </div>
            </template>
          </ControlStyleProperties>

          <!-- dataset -->
          <div
            class="dataset no-select"
            :class="{ active: !datasetCollapsed }"
            v-if="datasetFields"
          >
            <div class="">
              <span
                class="clicable"
                :class="{ 'text-primary': !datasetCollapsed }"
                @click.stop.prevent="toggleDatasetCollapsed()"
                style="font-size: 12pt"
              >
                <!-- <i class="fa fa-angle-down" v-if="datasetCollapsed"></i>
                  <i class="fa fa-angle-up" v-else></i> -->
                {{ $t("dataset") }}
              </span>
              <span class="pull-right">
                <!-- <span
                    class="btn btn-xs"
                    v-on:click.prevent.stop="resetDataset()"
                    v-if="dataSetDataList.length"
                  >
                    <i class="fa fa-trash"></i>
                  </span> -->
                <span
                  class="btn btn-xs"
                  v-on:click.prevent.stop="toggleDatasetDataPicker()"
                  :title="$t('new_data')"
                >
                  <i
                    class="fa"
                    :class="datasetDataPicker ? 'fa-minus' : 'fa-plus'"
                  ></i>
                </span>
              </span>
            </div>
            <ControlDataSelector
              v-if="datasetDataPicker && !datasetCollapsed"
              :connectorId="connectorId"
              :label="''"
              :allowedTypes="['bool', 'float', 'int', 'string']"
              @input="addDatasetDataId($event)"
            />
            <div v-if="dataSetDataList.length && !datasetCollapsed">
              <draggable class="list-group" v-model="dataSetDataList">
                <div v-for="(item, ix) in dataSetDataList" v-bind:key="ix">
                  <div class="list-group-item item group-item">
                    <div class="move-item">
                      <i class="glyphicon glyphicon-option-vertical"></i>
                      <div class="dataset-item-name">
                        {{ item.name || "" }}
                      </div>
                      <span class="item-buttons pull-right">
                        <span
                          class="btn btn-xs"
                          :class="isSimulating(item.id) ? 'text-orange' : ''"
                          v-on:click.prevent.stop="toggleSimulation(item.id)"
                        >
                          <i class="fa fa-history hidden-sm hidden-xs"></i>
                        </span>
                        <span
                          class="btn btn-xs"
                          v-on:click.prevent.stop="delDatasetDataId(ix)"
                        >
                          <i class="fa fa-trash"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </draggable>
              <div class="dataset-columns">
                <div class="simple-label">{{ $t("show") }}</div>
                <div
                  class="item clicable"
                  @click.stop.prevent="
                    dataSetSequenceColumn = !dataSetSequenceColumn
                  "
                >
                  <i
                    class="fa fa-check-square-o"
                    v-if="dataSetSequenceColumn"
                  ></i>
                  <i class="fa fa-square-o" v-else></i>
                  <span> {{ $t("row_number") }}</span>
                </div>
                <div
                  class="item clicable"
                  @click.stop.prevent="dataSetTimeColumn = !dataSetTimeColumn"
                >
                  <i class="fa fa-check-square-o" v-if="dataSetTimeColumn"></i>
                  <i class="fa fa-square-o" v-else></i>
                  <span> {{ $t("datetime") }}</span>
                </div>
                <div class="form-group form-group-sm" style="margin-top: 10px">
                  <div class="simple-label">{{ $t("missing_values") }}</div>
                  <select v-model="dataSetMissingValues" class="form-control">
                    <option value="last_value">{{ $t("last_value") }}</option>
                    <option value="linear_interpolation">
                      {{ $t("linear_interpolation") }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group form-group-sm target-cell">
                <div
                  class="input-group"
                  :class="isDataSetAddressValid ? '' : 'has-error'"
                >
                  <div
                    class="input-group-addon btn"
                    :class="cellSearch ? 'text-orange' : ''"
                    @click.stop.prevent="toggleCellSearch"
                  >
                    {{ $t("target_cell") }}
                  </div>
                  <input
                    type="text"
                    class="form-control text-center"
                    v-model="dataSetAddress"
                    :disabled="cellSearch"
                  />
                  <div
                    class="input-group-addon btn"
                    :class="cellSearch ? 'text-orange' : ''"
                    @click.stop.prevent="toggleCellSearch"
                  >
                    <i class="fa fa-crosshairs"></i>
                  </div>
                </div>
              </div>
              <div
                class="small text-danger text-center"
                v-if="!isDataSetAddressValid && dataSetAddress"
              >
                {{ $t("not_enough_room") }}
              </div>
            </div>
          </div>

          <!-- important while in use by the synoptic -->
          <div v-else style="margin-top: 10px"></div>
        </div>
        <div class="contentPanel" v-if="currentCell" style="margin: -10px 10px">
          <CellForm
            v-model="currentCell"
            :address="currentAddress"
            :dataSetConfig="isDataSetAddressValid ? dataSetConfig : null"
            :open="cellEdit"
          />
        </div>
      </TogglePanel>
    </TogglePanel>
  </div>
</template>

<script>
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlDataSelector from "@/components/synoptic/property-editor/controls/control-data-selector.vue";
import ControlStyleProperties from "@/components/synoptic/property-editor/controls/control-style-properties.vue";
import CellForm from "@/components/control-sidebar/property-editors/cell-form.vue";
import draggable from "vuedraggable";

const defFormat = {
  bool: "",
  string: "%s",
  float: "%.2f",
  int: "%d"
};

const defCell = () => {
  return {
    value: "",
    data_id: "",
    data_source: "constant",
    state_list: null,
    format: "",
    default: "",
    style: {
      color: "#333",
      "background-color": "#fff",
      "box-shadow": "none",
      "text-align": "center",
      "font-family": "Source Sans Pro",
      "font-size": "11pt",
      "font-style": "normal",
      "font-weight": "normal",
      "text-decoration": "none",
      padding: "0px 0px 0px 0px",
      width: "auto",
      "white-space": "nowrap"
    }
  };
};

const defTableStyle = () => {
  return {
    classes: {
      "table-bordered": true,
      "table-condensed": true,
      "table-striped": true,
      "table-hover": true
    },
    cssVars: {
      "--border-color": "gray",
      "--border-width": "1px"
    }
  };
};

export { defCell };

export default {
  name: "TableForm",
  props: {
    title: {
      type: String,
      required: false,
      default: "panel_properties"
    },
    headerFields: {
      type: Boolean,
      required: false,
      default: true
    },
    datasetFields: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    TogglePanel,
    ControlDataSelector,
    ControlStyleProperties,
    CellForm,
    draggable
  },
  data() {
    return {
      busy: false,
      info: null,
      historySearch: false,
      panels: {
        header: false,
        title: false,
        subTitle: false,
        table: false,
        cell: false
      },
      dataSources: ["constant", "data", "system"],
      expressionAppend: true,
      cellSearch: false,
      cellEdit: false,
      datasetDataPicker: false,
      datasetCollapsed: true
    };
  },
  computed: {
    connectorId() {
      return this.$store.getters["dashboard/dashboardEquipmentId"] || "";
    },
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    panel() {
      let currentDraftPanel =
        this.$store.getters["dashboard/currentDraftPanel"] || null;
      if (!currentDraftPanel) return null;
      if (currentDraftPanel.template == "DashboardTablePanel") {
        return currentDraftPanel;
      } else {
        if (!this?.info?.panelName) return null;
        return (
          (this.draft &&
            this.draft?.template &&
            (this.draft?.template?.panels || []).find(
              (i) => i.name == this.info.panelName
            )) ||
          null
        );
      }
    },
    // Control here is already the SynopticComponent for SynopticSimpleTable
    control: {
      set(value) {
        if (this.info.control) {
          this.info.control = value;
        }
      },
      get() {
        return this?.info?.control || null;
      }
    },
    sheet() {
      return this?.control?.sheet || null;
    },
    currentCell: {
      set(value) {
        if (!this.currentCell) return;
        if (value.data_source != this.currentCell.data_source) {
          if (value.data_source == "constant") {
            this.dataId = "";
            this.defaultValue = "";
          } else {
            this.defaultValue = "-";
          }
        }
        this.$set(this.sheet[this.info.row], this.info.column, value);
      },
      get() {
        if (this.sheet && this.info?.row >= 0 && this.info?.column >= 0) {
          return this.sheet[this.info.row][this.info.column];
        }
        return null;
      }
    },
    currentAddress() {
      if (this.currentCell) {
        let char =
          this.info.column <= 90
            ? String.fromCharCode(this.info.column + 65)
            : "!"; // 90=Z and it is a too big table
        return `${char}${this.info.row + 1}`;
      }
      return "";
    },
    xy() {
      if (this.currentCell) {
        return `${this.info.row},${this.info.column}`;
      }
      return "";
    },
    nRows() {
      return this?.sheet?.length || 0;
    },
    nColumns() {
      let max = 0;
      if (this.sheet) {
        this.sheet.forEach((row) => {
          max = row.length > max ? row.length : max;
        });
      }
      return max;
    },
    size() {
      return `${this.nRows}x${this.nColumns}`;
    },
    titleText: {
      set(vlr) {
        if (this?.control?.title) {
          this.$set(this.control.title, "text", vlr);
        }
      },
      get() {
        return this?.control?.title?.text || "";
      }
    },
    titleStyle: {
      set(vlr) {
        if (this?.control?.title) {
          this.$set(this.control.title, "style", vlr);
        }
      },
      get() {
        return this?.control?.title?.style;
      }
    },
    subTitleText: {
      set(vlr) {
        if (this?.control?.subTitle) {
          this.$set(this.control.subTitle, "text", vlr);
        }
      },
      get() {
        return this?.control?.subTitle?.text || "";
      }
    },
    subTitleStyle: {
      set(vlr) {
        if (this?.control?.subTitle) {
          this.$set(this.control.subTitle, "style", vlr);
        }
      },
      get() {
        return this?.control?.subTitle?.style;
      }
    },
    tableClasses() {
      let style = this?.control?.tableStyle || defTableStyle();
      let lst = [];
      for (var name in style.classes) {
        lst.push({
          name: name,
          title: this.$t(name.replace(/table-/i, "")),
          value: style.classes[name]
        });
      }
      return lst;
    },
    tableCellStyle: {
      set(value) {
        if (!this?.control) return;
        let style = this.control?.tableStyle || defTableStyle();
        let lst = (value?.border || "0px solid #666").split(" ");
        if (lst.length > 2) {
          this.$set(style.cssVars, "--border-width", lst.splice(0, 1)[0]);
          this.$set(style.cssVars, "--border-style", lst.splice(0, 1)[0]);
          this.$set(style.cssVars, "--border-color", lst.join(""));
          this.$set(this.control, "tableStyle", style);
        }
      },
      get() {
        let style = this?.control?.tableStyle || defTableStyle();
        let w = style.cssVars["--border-width"] || "0px";
        let s = style.cssVars["--border-style"] || "solid";
        let c = style.cssVars["--border-color"] || "#666";
        return {
          border: `${w} ${s} ${c}`
        };
      }
    },
    errors() {
      let entry = {
        cellStyle: "" // TODO: implement error parser
      };
      return entry;
    },
    extendedDataList() {
      return this.$store.getters["dashboard/extendedDataList"] || [];
    },
    dataSetConfig() {
      if (!this?.control) return null;
      return (
        this?.control?.dataSetConfig || {
          address: "",
          dataList: [],
          missingValues: "interpolation",
          timeColumn: true
        }
      );
    },
    dataSetAddress: {
      set(value) {
        if (!this?.control) return;
        let dataSetConfig = this.dataSetConfig;
        dataSetConfig.address = value || "";
        this.$set(this.control, "dataSetConfig", dataSetConfig);
        this.onDataSetConfigChanged();
      },
      get() {
        return this?.dataSetConfig?.address || "";
      }
    },
    dataSetDataList: {
      set(value) {
        if (!this?.control) return;
        let dataSetConfig = this.dataSetConfig;
        dataSetConfig.dataList = (value || []).map(({ id }) => ({
          data_id: id
        }));
        this.$set(this.control, "dataSetConfig", dataSetConfig);
        this.onDataSetConfigChanged();
      },
      get() {
        return (this?.dataSetConfig?.dataList || []).map((item) => {
          return item
            ? this.getEquipmentData(item.data_id) || {
              id: item.data_id,
              name: "?"
            }
            : null;
        });
      }
    },
    dataSetTimeColumn: {
      set(value) {
        if (!this?.control) return;
        let dataSetConfig = this.dataSetConfig;
        this.$set(dataSetConfig, "timeColumn", value);
        this.$set(this.control, "dataSetConfig", dataSetConfig);
        this.onDataSetConfigChanged();
      },
      get() {
        return this?.dataSetConfig?.timeColumn || false;
      }
    },
    dataSetSequenceColumn: {
      set(value) {
        if (!this?.control) return;
        let dataSetConfig = this.dataSetConfig;
        this.$set(dataSetConfig, "sequenceColumn", value);
        this.$set(this.control, "dataSetConfig", dataSetConfig);
        this.onDataSetConfigChanged();
      },
      get() {
        return this?.dataSetConfig?.sequenceColumn || false;
      }
    },
    dataSetMissingValues: {
      set(value) {
        if (!this?.control) return;
        let dataSetConfig = this.dataSetConfig;
        this.$set(dataSetConfig, "missingValues", value);
        this.$set(this.control, "dataSetConfig", dataSetConfig);
      },
      get() {
        return this?.dataSetConfig?.missingValues || "last_value";
      }
    },
    isDataSetAddressValid() {
      if (this.sheet) {
        let nCols =
          (this.dataSetDataList || []).length +
          (this.dataSetSequenceColumn ? 1 : 0) +
          (this.dataSetTimeColumn ? 1 : 0);
        let address = this.dataSetAddress || "";
        if (address) {
          let c = parseInt(address.charCodeAt(0) - 65);
          let r = parseInt(address.replace(/\D/g, "")) - 1;
          if (
            r >= 0 &&
            r <= this.sheet.length - 1 &&
            c >= 0 &&
            c + nCols <= this.sheet[0].length
          ) {
            return true;
          }
        }
      }
      return false;
    },
    cellWidth: {
      set(vlr) {
        if (this.currentCell) {
          let value = parseInt(vlr !== "" ? vlr : 0);
          this.currentCell.style.width = value ? value + "%" : "auto";
        }
      },
      get() {
        let vlr = "";
        if (this.currentCell) {
          let perc = this?.currentCell?.style?.width || "auto";
          vlr = perc != "auto" ? perc.replace(/\%/, "") : "";
        }
        return vlr;
      }
    },
    cellExpression: {
      set(value) {
        if (this.currentCell) {
          this.currentCell.value = value;
        }
      },
      get() {
        return this?.currentCell ? this.currentCell.value : "";
      }
    }
  },
  watch: {
    control: {
      handler(n) {
        if (this.panel) {
          // standalone panel
          this.$store.dispatch("dashboard/saveDraftPanel", {
            panel: this.panel,
            screenId: this.draft.screenId
          });
        } else {
          // synoptic control
        }
      },
      deep: true
    }
  },
  methods: {
    A1(r, c) {
      let char = c <= 25 ? String.fromCharCode(c + 65) : "!"; // 90=Z and it is a too big table
      return `${char}${r + 1}`;
    },
    R1C1(address) {
      return address && address.length >= 2
        ? {
          column: address.charCodeAt(0) - 64,
          row: parseInt(address.slice(1))
        }
        : null;
    },
    getEquipmentData(id) {
      if (!id) return null;
      return (this.extendedDataList || []).find((i) => i.id == id);
    },
    toggleTableClass(ix) {
      if (!this?.control) return;
      let lst = this.tableClasses;
      this.control = this.control || {};
      let tableStyle = this?.control?.tableStyle || defTableStyle();
      this.$set(
        tableStyle.classes,
        lst[ix].name,
        !tableStyle.classes[lst[ix].name]
      );
      this.$set(this.control, "tableStyle", tableStyle);
    },
    togglePanels(lst) {
      for (var name in this.panels) {
        this.panels[name] = lst.indexOf(name) > -1;
      }
    },
    onTableEvent($event) {
      let sheet = this.sheet;
      switch ($event.action) {
        case "sheet:activate": {
          this.info = $event.details;
          if ($event.minHeight !== undefined && !isNaN(parseInt($event.minHeight)) && this.control) {
            this.control.clientRect.height = $event.minHeight;
          }
          if ($event.minWidth !== undefined && !isNaN(parseInt($event.minWidth)) && this.control) {
            this.control.clientRect.width = $event.minWidth;
          }
          this.cellEdit = false;
          break;
        }

        case "cell:activate": {
          if (this.cellSearch) {
            this.dataSetAddress = this.A1(
              $event.details.row,
              $event.details.column
            );
            this.cellSearch = false;
            return;
          }
          this.datasetDataPicker = false; // !important - since data picker crashes if more than one instance.
          this.togglePanels(["cell"]);
          this.info = $event.details;
          if (this?.currentCell?.data_id !== "") {
            this.currentCell.data_source = "data";
          }
          this.busy = true;
          this.cellEdit = false;
          this.$nextTick(() => {
            this.busy = false;
          });
          break;
        }

        case "cell:edit": {
          this.cellEdit = false;
          this.$nextTick(() => {
            this.cellEdit = true;
          });
          break;
        }

        case "cell:set_value": {
          this.togglePanels(["cell"]);
          this.info = $event.details;
          this.cellExpression = $event.details.value;
          break;
        }

        case "cell:set_width": {
          this.togglePanels(["cell"]);
          this.info = $event.details;
          let vlr = $event.details.width;
          if (this.currentCell) {
            let value = parseInt(vlr !== "" ? vlr : 0);
            this.currentCell.style.width = value ? value + "%" : "auto";
          }
          break;
        }

        case "title:activate": {
          this.togglePanels(["header", "title"]);
          this.info = $event.details;
          this.cellEdit = false;
          this.$nextTick(() => {
            if (this.$refs.inpTitle) {
              this.$refs.inpTitle.focus();
            }
          });
          break;
        }

        case "sub_title:activate": {
          this.togglePanels(["header", "subTitle"]);
          this.info = $event.details;
          this.cellEdit = false;
          this.$nextTick(() => {
            if (this.$refs.inpSubTitle) {
              this.$refs.inpSubTitle.focus();
            }
          });
          break;
        }

        case "row:add_above": {
          if (!sheet) return;
          this.info = $event.details;
          let row;
          if (sheet.length) {
            row = JSON.parse(JSON.stringify(sheet[this.info.row]));
          } else {
            row = Array.from({ length: this.nColumns }).map(() => {
              return defCell();
            });
          }
          sheet.splice(this.info.row, 0, row);
          this.$root.$emit("panel:resized");
          this.cellEdit = false;
          break;
        }

        case "row:add_below": {
          if (!sheet) return;
          this.info = $event.details;
          let row;
          if (sheet.length) {
            row = JSON.parse(JSON.stringify(sheet[this.info.row]));
          } else {
            row = Array.from({ length: this.nColumns }).map(() => {
              return defCell();
            });
          }
          sheet.splice(this.info.row + 1, 0, row);
          this.$root.$emit("panel:resized");
          this.cellEdit = false;
          break;
        }

        case "row:del": {
          if (!sheet) return;
          this.info = $event.details;
          sheet.splice(this.info.row, 1);
          if (!this.nRows) {
            let row = Array.from({ length: 1 }).map(() => {
              return defCell();
            });
            sheet.splice(0, 0, row);
          }
          if (this.info.row > this.nRows - 1) {
            this.info.row = this.nRows - 1;
          }
          this.$root.$emit("panel:resized");
          this.cellEdit = false;
          break;
        }

        case "row:reset_style": {
          if (!sheet) return;
          this.info = $event.details;
          sheet[this.info.row].forEach((col) => {
            this.$set(col, "style", defCell().style);
            this.$set(col, "format", "");
          });
          this.cellEdit = false;
          break;
        }

        case "row:reset_content": {
          if (!sheet) return;
          this.info = $event.details;
          sheet[this.info.row].forEach((col) => {
            let standard = defCell();
            for (var p in standard) {
              this.$set(col, p, standard[p]);
            }
          });
          this.cellEdit = false;
          break;
        }

        case "column:add_left": {
          if (!sheet) return;
          this.info = $event.details;
          sheet.forEach((row) => {
            let cell = defCell();
            let ref = JSON.parse(JSON.stringify(row[this.info.column]));
            cell.style = ref.style;
            cell.format = ref.format;
            row.splice(this.info.column, 0, cell);
          });
          this.cellEdit = false;
          if (this.dataSetAddress) {
            this.onDataSetConfigChanged();
          }
          break;
        }

        case "column:add_right": {
          if (!sheet) return;
          this.info = $event.details;
          sheet.forEach((row) => {
            let cell = defCell();
            let ref = JSON.parse(JSON.stringify(row[this.info.column]));
            cell.style = ref.style;
            cell.format = ref.format;
            row.splice(this.info.column + 1, 0, cell);
          });
          this.cellEdit = false;
          if (this.dataSetAddress) {
            this.onDataSetConfigChanged();
          }
          break;
        }

        case "column:del": {
          if (!sheet) return;
          this.info = $event.details;
          sheet.forEach((row) => {
            row.splice(this.info.column, 1);
          });
          if (!this.nColumns) {
            sheet.forEach((row) => {
              row.splice(0, 0, defCell());
            });
          }
          if (this.info.column > this.nColumns - 1) {
            this.info.column = this.nColumns - 1;
          }
          this.cellEdit = false;
          if (this.dataSetAddress) {
            this.onDataSetConfigChanged();
          }
          break;
        }

        case "column:reset": {
          if (!sheet) return;
          this.info = $event.details;
          sheet.forEach((row) => {
            this.$set(row[this.info.column], "style", defCell().style);
            this.$set(row[this.info.column], "format", "");
          });
          this.cellEdit = false;
          break;
        }

        case "column:set_width": {
          if (!sheet) return;
          this.info = $event.details;
          let width = parseInt(this.info.width !== "" ? this.info.width : 0);
          let row = this.info.row;
          let column = this.info.column;
          if (
            width &&
            row >= 0 &&
            row < this.sheet.length &&
            column >= 0 &&
            column < this.sheet[row].length
          ) {
            this.togglePanels(["cell"]);
            sheet.forEach((row) => {
              var cell = row[this.info.column];
              cell.style.width = width ? width + "%" : "auto";
            });
          }
          break;
        }

        case "cell:reset": {
          if (!sheet) return;
          this.info = $event.details;
          let cell = sheet[this.info.row][this.info.column];
          this.$set(cell, "style", defCell().style);
          this.$set(cell, "format", "");
          this.cellEdit = false;
          break;
        }
      }
    },
    addDatasetDataId(id) {
      if (id) {
        if (this?.control?.dataSetConfig) {
          let lst = JSON.parse(
            JSON.stringify(this?.control?.dataSetConfig?.dataList || [])
          );
          lst.push({ data_id: id });
          this.control.dataSetConfig.dataList = lst;
        }
        this.datasetDataPicker = false;
        this.onDataSetConfigChanged();
      }
    },
    delDatasetDataId(ix) {
      if (this?.control?.dataSetConfig) {
        let lst = JSON.parse(
          JSON.stringify(this?.control?.dataSetConfig?.dataList || [])
        );
        lst.splice(ix, 1);
        this.control.dataSetConfig.dataList = lst;
        if (!lst.length) {
          this.control.dataSetConfig.address = "";
          this.datasetDataPicker = false;
          this.datasetCollapsed = true;
        }
        this.onDataSetConfigChanged();
      }
    },
    resetDataset() {
      this.control.dataSetConfig.dataList = [];
      this.control.dataSetConfig.address = "";
      this.onDataSetConfigChanged();
    },
    onDataSetConfigChanged() {
      let n = this.dataSetConfig;
      if (n && n.address && n.dataList.length) {
        let rc = this.R1C1(n.address);
        if (this.sheet.length >= rc.row) {
          let row = rc.row - 1;
          // Sequence Column
          var sCol = n.sequenceColumn ? 0 : -1;
          // Time Column
          var tCol = n.timeColumn ? (sCol == 0 ? 1 : 0) : -1;
          // Data inicial column
          var dCol = (sCol >= 0 ? 1 : 0) + (tCol >= 0 ? 1 : 0);
          for (var col = rc.column - 1; col < this.sheet[row].length; col++) {
            if (col == sCol) {
              this.sheet[row][col].data_id = "";
              this.sheet[row][col].data_source = "constant";
              this.sheet[row][col].format = "%d";
            } else if (col == tCol) {
              this.sheet[row][col].data_id = "";
              this.sheet[row][col].data_source = "constant";
              this.sheet[row][col].format = "DD/MM/YYYY HH:mm:ss";
            } else if (col >= dCol) {
              let info = n.dataList[col - dCol] || null;
              if (info) {
                let data = this.dataSetDataList.find(
                  ({ id }) => id == info.data_id
                );
                this.sheet[row][col].data_id = info.data_id;
                this.sheet[row][col].data_source = "data";
                this.sheet[row][col].format =
                  (data && (data.custom_format || defFormat[data.type])) || "";
              } else {
                this.sheet[row][col].data_id = "";
                this.sheet[row][col].data_source = "constant";
                this.sheet[row][col].format = "";
              }
            }
          }
        }
      }
    },
    toggleDatasetDataPicker() {
      if (this.datasetDataPicker) {
        this.datasetDataPicker = false;
      } else {
        this.togglePanels(["table"]);
        this.datasetCollapsed = false;
        this.$nextTick(() => {
          this.datasetDataPicker = true; // wait panel transition before switch it on;
        });
      }
    },
    toggleDatasetCollapsed() {
      if (this.dataSetDataList.length) {
        this.datasetCollapsed = !this.datasetCollapsed;
        this.datasetDataPicker = false;
      } else {
        this.toggleDatasetDataPicker();
      }
    },
    isSimulating(id) {
      let history = this.$store.getters["history/entries"] || {};
      return history && id in history ? true : false;
    },
    toggleSimulation(id) {
      if (this.isSimulating(id)) {
        this.$store.dispatch("history/reset");
      } else {
        this.$store.dispatch("history/simulate", id);
      }
    },
    toggleCellSearch() {
      this.cellSearch = !this.cellSearch;
      if (this.cellSearch) {
        this.dataSetAddress = "";
      }
    },
    toggleCellForm() {
      if (this?.$refs?.cellForm?.toggleFloatPanel) {
        this.$refs.cellForm.toggleFloatPanel();
      }
    }
  },
  created() {
    this.$root.$on("table:event", this.onTableEvent);
    let panel = this.$store.getters["dashboard/currentDraftPanel"];
    if (panel && panel.template == "DashboardTablePanel") {
      this.onTableEvent({
        action: "sheet:activate",
        details: {
          panelName: panel.name,
          control: panel.options
        }
      });
    }
  },
  beforeDestroy() {
    this.$root.$off("table:event", this.onTableEvent);
  }
};
</script>

<style scoped>
/* label {
  margin-bottom: 0;
} */

.inner-panel {
  padding-left: 4px;
}

.inner-panel .form-group {
  margin-left: 4px;
  margin-bottom: 4px;
  position: relative;
}

.inner-label {
  position: absolute;
  top: -8px;
  left: 5px;
  font-size: 8pt;
  background-color: white;
}

.small-checkbox-label {
  vertical-align: text-top;
  white-space: nowrap;
  font-size: 8pt;
}

.contentPanel {
  margin-top: -10px;
  /* border-top: 1px solid rgb(206, 206, 206); */
}

.dataSource {
  background: whitesmoke;
  padding: 4px 0;
}

.dataSource > label > span {
  vertical-align: top;
  margin: 0 15px 0 2px;
}
.expression-icon {
  color: #666;
  font-size: 80%;
  font-weight: 600;
  letter-spacing: 0px;
  font-family: monospace;
}

.expression-icon.active {
  color: #025dca;
}

.list-group-item {
  padding: 5px;
}
.move-item:hover {
  cursor: move;
  opacity: 0.8;
}

.dataset {
  margin: 0;
  padding: 6px 4px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid transparent;
}

.dataset.active {
  background-color: whitesmoke;
  border-color: lightgray;
}

.dataset-columns {
  padding: 5px 2px;
  margin-top: -15px;
  clear: both;
}
.dataset-columns > .item {
  display: inline-block;
  padding-right: 12px;
  font-size: 95%;
}

.simple-label {
  font-weight: 600;
}

.target-cell {
  padding: 5px 20px;
  background: #e8e8e8;
  border-radius: 5px;
  margin: -15px 2px 0 2px;
}
.dataset-item-name {
  display: inline-block;
  max-width: calc(100% - 60px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: text-bottom;
}

.cell-edit-button {
  border: 1px solid rgb(158, 158, 158);
  border-radius: 5px;
  padding: 0 4px;
  font-size: 80%;
}

.cell-edit-button:hover {
  color: #5e82a2;
  background-color: rgb(219, 219, 219);
  border-color: rgb(209, 209, 209);
}

.cell-edit-button > i {
  font-size: 70%;
  vertical-align: top;
  padding: 5px 0 0 3px;
}
</style>
